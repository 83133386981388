
<template>
  <modal :visible="value" @onClosed="doClose" size="xxlarge">
    <span slot="head">
      <span>{{ $t(1041) }}</span>
    </span>
    <div slot="body">
      <div v-loading="loading" class="content-nobg">
        <div class="sale flex">
          <div class="left">
            <div class="btns">
              <el-button
                v-for="btn in c2cRatesData"
                :key="btn.coin"
                @click="handleSelectCoin(btn.coin)"
                :class="['btnx', { selectCoin: selectCoin === btn.coin }]"
                >{{ btn.coin }}</el-button
              >
            </div>

            <div class="sale-form">
              <el-form :model="drawData" :rules="rules" ref="form" inline-message>
                <el-form-item label="法幣類別：">
                  <el-select v-model="drawData.coin" placeholder="请选择">
                    <el-option
                      v-for="item in bankOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="amount">
                  <span class="flex">
                    <span class="flex1">出售數量</span>
                    <el-button
                      type="text"
                      @click="$router.push({ name: 'Transfer' })"
                      >{{ $t(188) }}</el-button
                    >
                  </span>
                  <el-input
                    type="Number"
                    v-model="drawData.amount"
                    :placeholder="`最大可售${maxAmount}`"
                  >
                    <template slot="append">
                      <div class="enterNum-append">
                        <span>{{ selectCoin }}</span>
                        <el-button type="text" @click="drawData.amount = maxAmount">全部</el-button>
                      </div>
                    </template>
                  </el-input>
                  <span>參考單價 ≈ 4292.898660 USD</span>
                </el-form-item>
              </el-form>

              <div class="recive mt10">
                <div v-if="isBank" class="bank">
                  <p class="fwb mb10">您的收款方式</p>
                  <p class="flex">
                    <span>銀行名稱：</span>
                    <span>{{ isBank.bankBranch }}</span>
                  </p>
                  <p class="flex">
                    <span>銀行帳號：</span>
                    <span>{{ isBank.cardNo }}</span>
                  </p>
                  <p class="flex">
                    <span>姓名：</span>
                    <span>{{ isBank.realname }}</span>
                  </p>
                </div>
                <span v-else>您還沒有綁定相應的收款方式，無法出售。</span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="flex align-center">
              <span class="flex1">出售 訂單記錄：</span>
              <el-button @click="showBtns = !showBtns" class="btnx"
                >篩選</el-button
              >
            </div>

            <div v-show="showBtns" class="filter-btns">
              <p>類型</p>
              <div class="flex">
                <el-button
                  class="btnx"
                  :class="{ active: history === 'deposit' }"
                  @click="handleFilter('deposit')"
                  >購買</el-button
                >
                <el-button
                  class="btnx"
                  :class="{ active: history === 'withdraw' }"
                  @click="handleFilter('withdraw')"
                  >出售</el-button
                >
              </div>
              <p>狀態</p>
              <div class="flex">
                <el-button
                  class="btnx"
                  :class="{ active: queryData.status === 0 }"
                  @click="handleFilter('', 0)"
                  >全部</el-button
                >
                <el-button
                  class="btnx"
                  :class="{ active: queryData.status === 1 }"
                  @click="handleFilter('', 1)"
                  >處理中</el-button
                >
                <el-button
                  class="btnx"
                  :class="{ active: queryData.status === 2 }"
                  @click="handleFilter('', 2)"
                  >通過</el-button
                >
                <el-button
                  class="btnx"
                  :class="{ active: queryData.status === 3 }"
                  @click="handleFilter('', 3)"
                  >失敗</el-button
                >
              </div>
            </div>
            <div v-loading="historyLoading" class="content-nobg">
              <div
                v-for="(item, i) in c2cHistoryData"
                :key="i"
                class="sale-record"
              >
                <div class="flex mt10 fwb">
                  <span class="flex1"
                    ><span
                      :class="[history === 'deposit' ? 'success' : 'danger']"
                      >{{ history === "withdraw" ? "出售" : "購買" }}</span
                    >
                    {{ item.coin }}</span
                  >
                  <span v-if="item.status === 1">處理中</span>
                  <span v-if="item.status === 2" class="success">通過</span>
                  <span v-if="item.status === 3" class="danger">失敗</span>
                </div>
                <div class="flex sale-record-item">
                  <span class="flex1"
                    >價格：<span v-if="item.exchangeRate"
                      >{{ item.exchangeRate }} {{ item.currency }}</span
                    ></span
                  >
                  <span>{{ item.createTime }}</span>
                </div>
                <div class="flex sale-record-item">
                  <span class="flex1"
                    >數量：<span v-if="item.soldAmount"
                      >{{ item.soldAmount }} {{ item.coin }}</span
                    ></span
                  >
                  <span>{{ item.exchangeAmount }} USD</span>
                </div>
                <div class="flex sale-record-item">
                  <span class="flex1">銀行名稱：{{ item.bankCode }}</span>
                  <span>{{ item.cardNo }}</span>
                </div>
                <div v-if="item.auditComment" class="flex sale-record-item">
                  <span class="flex1">COMMON.62：</span>
                  <span>{{ item.auditComment }}</span>
                </div>
              </div>
              <div v-if="!c2cHistoryData" class="no-data">
                <img src="@/assets/img/nodata.png" alt="nodata" />
                <p>{{ $t(1018) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="foot">
      <div class="text-center">
        <!-- <el-button @click="doClose">{{ $t(164) }}</el-button> -->
        <el-button type="danger" :loading="loading" @click="handleSentDraw">{{
          $t(1041)
        }}</el-button>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal";
import {
  getC2cRates,
  getC2cDepositHistory,
  getC2cWithdrawHistory,
  editUserInfo,
  getBankCards,
	sentC2cDraw
} from "@/api";
import utils from "@/utils/misc";
export default {
  name: "SettingNickname",
  components: { Modal },
  props: {
    value: Boolean,
		curCoinList: Array
  },

  data() {
    return {
      loading: false,
      historyLoading: false,
      showBtns: false,
      history: "withdraw",
      queryData: {
        status: 0,
        lastId: 0,
        pageSize: 10,
      },
			drawData: {
				amount: 0,
				cardId: 0,
				coin: "",
				currency: ""
			},
      activeName: "first",
      selectCoin: "ETH",
      // form: {
      //   enterNum: "",
      // },
      c2cRatesData: [],
      c2cHistoryData: [],
      cardsData: [],
      selectBank: "USD",
      bankOptions: [
        {
          value: "1",
          label: "RUB",
        },
        {
          value: "2",
          label: "EUR",
        },
        {
          value: "3",
          label: "JPY",
        },
        {
          value: "4",
          label: "CNY",
        },
        {
          value: "5",
          label: "USD",
        },
        {
          value: "6",
          label: "HKD",
        },
        {
          value: "7",
          label: "GBP",
        },
        {
          value: "8",
          label: "THB",
        },
      ],
    };
  },
  mounted() {
    this.fetchC2cRates();
		console.log('curCoinList:',this.curCoinList);
  },
  computed: {
		maxAmount(){
				return this.curCoinList.find( e => e.coin === this.selectCoin).availableAmount
		},
		isBank() {
			return this.cardsData.find((item) => item.currency === this.selectBank);
		},
		rules() {
			return {
				amount: [
					{
						validator: (rule, value, callback) => {
							if (value === "") {
								return callback(new Error(this.$t(1043)));
							}
							callback();
						},
					},
				],
			};
		},
  },
  methods: {
    doClose() {
      this.$emit("input", false);
    },
    handleSelectCoin(type) {
      this.selectCoin = type;
    },
    fetchC2cRates() {
      this.loading = true;
      Promise.all([
        getC2cRates(),
        this.handleC2cHistory(this.queryData),
        getBankCards(),
      ])
        .then((res) => {
          this.c2cRatesData = res[0].coins;
          this.cardsData = res[2].cards;
          this.selectCoin = this.c2cRatesData[0].coin;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleC2cHistory(data) {
      this.historyLoading = true;
      if (this.history === "withdraw") {
        return new Promise((resolve, reject) => {
          getC2cWithdrawHistory(data)
            .then((res) => {
              this.c2cHistoryData = res.orders;
              resolve();
            })
            .finally(() => {
              this.historyLoading = false;
            });
        });
      } else {
        return new Promise((resolve, reject) => {
          getC2cDepositHistory(data)
            .then((res) => {
              this.c2cHistoryData = res.orders;
              resolve();
            })
            .finally(() => {
              this.historyLoading = false;
            });
        });
      }
    },
    handleFilter(type, status) {
      if (type) {
        this.history = type;
        this.handleC2cHistory(this.queryData);
      } else {
        this.queryData.status = status;
        this.handleC2cHistory(this.queryData);
      }
    },
    handleSentDraw() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
					const data = {
						amount: +this.drawData.amount,
						cardId: +this.isBank.id,
						coin: this.selectCoin,
						currency: this.drawData.coin
					}
          sentC2cDraw(data)
            .then((res) => {
              this.doClose();
              this.showSuccessMsg(this.$t(1062))
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sale {
  .btnx {
    height: 36px;
    padding: 5px 10px;
  }
  .left {
    width: 50%;
    min-height: 500px;
    padding: 0 15px;
    border-right: 1px solid #eee;
    .btns {
      padding: 15px 0;
      border-bottom: 1px solid #eee;
    }
    .sale-form {
      padding: 15px 0;
    }
    .enterNum {
    }
  }
  .right {
    width: 50%;
    height: 500px;
    overflow-y: auto;
    padding: 0 15px;
    .sale-record {
      padding: 10px 0;
      .sale-record-item {
        border-bottom: 1px solid #999;
      }
    }
    .filter-btns {
      padding: 10px 15px;
      background: #eee;
      p {
        margin: 5px 0;
      }
      .active {
        background: $primaryColor;
        color: #fff;
      }
    }
    .no-data {
      margin-top: 50px;
      img {
        max-width: 100px;
      }
    }
  }
}
.selectCoin {
  color: #fff !important;
  background: $primaryColor;
}
::v-deep.el-input-group__append {
  color: #999;
  width: 95px;
  padding: 0 20px 0 0;
  .enterNum-append {
    display: inline-flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
}
</style>